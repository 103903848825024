import React, { useEffect, useState } from "react";

const TypingAnimation = ({ phrases, style }) => {
  const [text, setText] = useState("");
  const [phase, setPhase] = useState("typing");
  const [showCursor, setShowCursor] = useState(true);
  const [stopCursor, setStopCursor] = useState(false);

  useEffect(() => {
    let timeout;

    const cursorBlink = setInterval(() => {
      if (!stopCursor) {
        setShowCursor((prev) => !prev);
      }
    }, 500);

    if (phase === "typing") {
      const currentPhrase = phrases[0];
      if (text.length < currentPhrase.length) {
        timeout = setTimeout(() => {
          setText(currentPhrase.slice(0, text.length + 1));
        }, 150);
      } else {
        timeout = setTimeout(() => {
          setPhase("erasing");
        }, 900);
      }
    } else if (phase === "erasing") {
      if (text.length > 0) {
        timeout = setTimeout(() => {
          setText(text.slice(0, text.length - 1));
        }, 50);
      } else {
        timeout = setTimeout(() => {
          setPhase("final");
        }, 500);
      }
    } else if (phase === "final") {
      const finalPhrase = phrases[1];
      if (text.length < finalPhrase.length) {
        timeout = setTimeout(() => {
          setText(finalPhrase.slice(0, text.length + 1));
        }, 100);
      } else {
        setTimeout(() => {
          setStopCursor(true);
        }, 5000);
      }
    }

    return () => {
      clearTimeout(timeout);
      clearInterval(cursorBlink);
    };
  }, [text, phase, stopCursor]);

  return (
    <div
      style={{
        fontSize: "3.66vw",
        fontFamily: "Poppins",
        marginLeft: 12,
        color: "#134B82",
        whiteSpace: "wrap",
        wrap: "wrap",
        textAlign: "center",
        ...style,
      }}
    >
      {text}
      {!stopCursor && (
        <span style={{ visibility: showCursor ? "visible" : "hidden" }}>|</span>
      )}
    </div>
  );
};

export default TypingAnimation;
