// Default imports
import { Render } from "@8_dev/sjson-web";
import { useState, useEffect } from "react";
import { ShowPage } from "context/showPage";

// Theme
import Theme from "theme";
import bcg from "assets/images/bcg/bg-menu-moon.svg";

export default ({ viewMode }) => {
  const { PageInfo } = ShowPage();
  const [ContentPage, setContentPage] = useState("PageFull");
  const [scrollOpacity, setScrollOpacity] = useState(0); // Estado para controlar a opacidade do fundo

  // Função para manipular a rolagem
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY; // Captura o quanto foi rolado no eixo Y
      const maxScroll = document.body.scrollHeight - window.innerHeight; // Rolagem máxima possível
      const opacity = Math.min(scrollTop / maxScroll, 1); // Calcula a opacidade (máximo 1)
      setScrollOpacity(opacity);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Navbar component
  const Nav = {
    component: "container",
    style: {
      position: "fixed",
      display: "flex",
      width: "100%",
      minHeight: 88,
      backgroundImage: `url(${bcg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      // zIndex: 999,
      top: -2,
      overflowY: "hidden",
      overflowX: "hidden",
    },
    children: [],
  };

  // Main content container
  return {
    component: "container",
    style: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      minHeight: "100%",
      maxHeigth: "100vh",
      overflowY: "hidden",
      overflowX: "hidden",
      transition: "background 0.2s linear",
    },
    children: [
      Nav,
      //background que deve ter o efeito
      {
        component: "container",
        style: {
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          overflowY: "auto",
          overflowX: "hidden",
          backgroundImage: `linear-gradient(to top, ${Theme.colors.bg.dark.bottom} 10%, ${Theme.colors.bg.dark.top} 34%)`,
        },
        children: [
          {
            component: "container",
            style: {
              display: "flex",
              width: "100%",
              minHeight: "100vh",
              flexDirection: "column",
              overflowY: "hidden",
              overflowX: "hidden",
            },
          },
          {
            component: "container",
            style: {
              display: "flex",
              width: "100%",
              minHeight: "100vh",
              flexDirection: "column",
              overflowY: "hidden",
              overflowX: "hidden",
            },
          },
        ],
      },
    ],
  };
};
