// Icon component
import Theme from "theme";
import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

import ClickSound from "assets/sounds/click.mp3";

import { Howl } from "howler";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const soundPlay = async (src, time, interval) => {
  const sound = new Howl({
    src,
    html5: true,
    volume: 0.5,
    loop: false,
  });
  for (let i = 0; i < time; i++) {
    await delay((interval || 0) * i);
    sound.play();
  }
};

//criar um component text dessa estrutura que monitore o hover
export default (param) => {

  let viewMode = param?.viewMode;
  
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const baseStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    gap: 4,
    ...param?.style?.titleBox,
    backgroundColor: isHovered
      ? param?.style?.backgroundColorHover
      : param?.style?.backgroundColorDefault,
    transform: isHovered ? "scale(1.1)" : "scale(1)",
    transition: "transform 0.2s ease, background-color 0.2s ease",
    borderRadius: 4,
  };

  const titleStyle = {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    whiteSpace: "nowrap",
    textAlign: "center",
    fontSize: 14,
    fontFamily: Theme.fontFamilies.primary,
    cursor: "pointer",
    transition: "color 0.2s ease",
    color: isHovered
      ? param?.style?.titleColorHover
      : param?.style?.titleColorDefault,
    ...param?.style?.title,
    borderRadius: 4,
  };

  return {
    component: "container",
    style: baseStyle,
    onClick: async () => {
      
      // soundPlay(ClickSound, 1);
      if (param?.onClick)
        try {
          await param.onClick();
        } catch (err) {
          console.log("icon err", err);
        }
    },
    children: [
      {
        render: (
          <div
            style={titleStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {param?.title || ""}
          </div>
        ),
      },
    ],
  };
};
