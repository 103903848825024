// Default imports
import { Render } from "@8_dev/sjson-web";

import { useState, useEffect, useRef } from "react";
import { ShowPage } from "context/showPage";
import Fade from "react-reveal/Fade";
import { ShowPopup } from "context/popup";

// Theme
import Theme, { setTheme, getTheme } from "theme";

//icons
import bcgWaterTextureDark from "assets/images/bcg/bg-texture-water-dark.svg";
import bcgWaterTextureLight from "assets/images/bcg/bg-texture-water-light.svg";
import bcgFishDark from "assets/images/bcg/bg-fish-dark.svg";
import bcgFishLight from "assets/images/bcg/bg-fish-light.svg";

import bcgMapa from "assets/images/bcg/bg-map.svg";
import bcgOctopusFooter from "assets/images/bcg/octopus.svg";
import bcgBubbles from "assets/images/bcg/bubbles.svg";
import LogoOctalFooter from "assets/images/logo/icon-octal.png";
import IconInstagram from "assets/images/logo/icon-instagram.svg";
import IconFacebook from "assets/images/logo/icon-facebook.svg";
import IconWhatsapp from "assets/images/logo/icon-whatsapp.svg";
import IconLinkedin from "assets/images/logo/icon-linkedin.svg";
import HortaIcon from "assets/images/menu/icon-horta.svg";
import PetinIcon from "assets/images/menu/icon-petin.svg";
import DreamIcon from "assets/images/menu/icon-dream.svg";

//skills
import skillDev from "assets/images/skill/dev.png";
import skillSecurity from "assets/images/skill/security.png";
import skillLib from "assets/images/skill/lib.png";
import skillInfra from "assets/images/skill/infra.png";

//mapa
import StepMap1 from "assets/images/map/01-proposito.png";
import StepMap2 from "assets/images/map/02-porto-das-ideias.png";
import StepMap3 from "assets/images/map/03-caverna-dos-prototipos.png";
import StepMap4 from "assets/images/map/04-montanha-da-estruturação.png";
import StepMap5 from "assets/images/map/05-vale-do-desenvolvimento.png";
import StepMap6 from "assets/images/map/06-pantano-dos-testes.png";
import StepMap7 from "assets/images/map/07-ilha-do-lançamento.png";
import StepMap8 from "assets/images/map/08-conclusão-do-mapa-do-tesouro.png";

//components
import AnimationText from "components/web/animationText";
import Button from "components/web/button";
import Icon from "components/web/icon";
import SkillContent from "components/web/skillContent";
import InfoContent from "components/web/infoContent";
import Input from "components/web/input";
import CardView from "components/web/cardView";
import CardInfo from "components/web/cardInfo";
import PopUp from "components/web/popup";

//pages

import Petin from "./projects/petin/index";
import Horta from "./projects/horta/index";
import Dream from "./projects/dream/index";

export default ({ viewMode, idxPageY }) => {
  const resultRef = useRef(null);
  const { PageInfo } = ShowPage();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const { PopupShow, PopupClose } = ShowPopup();

  const [ContentShow, setContentShow] = useState("Petin");

  const customScrollbarStyle = () => `
    ::-webkit-scrollbar {
      width: 2px;
      height: 8px;
    }
    ::-webkit-scrollbar-track {
       background:  ${Theme.colors.bg.dark.bottom};
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  `;

  // Função para rolar até o elemento
  const scrollToResult = () => {
    if (resultRef.current) {
      resultRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      console.log("resultRef está null, verifique a atribuição do ref.");
    }
  };

  useEffect(() => {
    console.log(getTheme());
    const styleTag = document.createElement("style");
    styleTag.id = "custom-scrollbar-style";
    styleTag.textContent = customScrollbarStyle();
    document.head.appendChild(styleTag);

    return () => {
      const existingStyleTag = document.getElementById(
        "custom-scrollbar-style"
      );
      if (existingStyleTag) {
        document.head.removeChild(existingStyleTag);
      }
    };
  }, []);

  const inputStyle = {
    select: {
      width: "100%",
      height: 48,
      border: "1px solid #bfbfbf",
      backgroundColor: "transparent",
      borderRadius: 8,
      paddingLeft: 12,
      fontFamily: "Poppins",
      marginBottom: 4,
    },
    input: {
      width: "100%",
      height: 48,
      border: "1px solid #bfbfbf",
      borderRadius: 8,
      paddingLeft: 14,
      fontFamily: "Poppins",
    },
    title: {
      fontFamily: "Poppins",
      fontSize: "1.2vh",
      marginLeft: 4,
      // color: "white",
    },
  };

  const inputName = Input({
    placeholder: "Nome completo",
    value: name,
    setValue: setName,
    style: {
      ...inputStyle,
    },
  });

  const inputEmail = Input({
    placeholder: "Email",
    value: email,
    setValue: setEmail,
    style: {
      ...inputStyle,
    },
  });

  const inputPhone = Input({
    placeholder: "Telefone",
    mask: "(99) 99999-9999",
    value: phone,
    setValue: setPhone,
    style: {
      ...inputStyle,
    },
  });

  const inputMessage = Input({
    placeholder: "Deixe-nos uma mensagem",
    value: message,
    setValue: setMessage,
    style: {
      ...inputStyle,
      input: {
        height: "12vh",
        border: "1px solid #bfbfbf",
        borderRadius: 8,
        paddingLeft: 12,
        fontFamily: "Poppins",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
    },
  });

  return {
    component: "container",
    style: {
      display: "flex",
      width: "100%",
      height: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      transition: "background 0.5s ease",
      overflowX: "hidden",
      background:
        getTheme() === "dark"
          ? `linear-gradient(to top, ${Theme.colors.bg.dark.bottom} 18%, ${Theme.colors.bg.dark.top} 100%)`
          : `linear-gradient(to top, rgba(0,0,0, 1) 18%, rgba(0, 126, 169, 1) 100%)`,
    },
    children: [
      {
        component: "container",
        style: {
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          overflow: "hidden",
          backgroundImage:
            getTheme() === "dark"
              ? `url(${bcgWaterTextureDark})`
              : `url(${bcgWaterTextureLight})`,
          backgroundRepeat: "repeat",
          backgroundSize: "contain",
          backgroundPosition: "top",
        },
        children: [
          //bcg-fish \/
          {
            component: "container",
            style: {
              display: "flex",
              maxWidth: "100%",
              height: "100vh",
              flexDirection: "column",
              overflow: "hidden",
              backgroundImage:
                getTheme() === "dark"
                  ? `url(${bcgFishDark})`
                  : `url(${bcgFishLight})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "top",
            },
            children: [
              {
                component: "container",
                style: {
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                },
                children: [
                  //container text
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      width: "100%",
                      height: "auto",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: 4,
                      gap: 32,
                    },
                    children: [
                      //textIntro
                      {
                        component: "text",
                        text: "Inovação e Excelência em Desenvolvimento de Software.",
                        style: {
                          fontFamily: Theme.fontFamilies.regular,
                          color: Theme.colors.secondary.light,
                          textAlign: "center",
                          fontSize:
                            viewMode === "desktop"
                              ? Theme.sizes.size_20
                              : Theme.sizes.size_18,
                          fontWeight: Theme.fontWeight.extraLight,
                        },
                      },
                      //slogan
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          width: "100%",
                          minHeight: viewMode === "desktop" ? "6vh" : "4vh",
                          maxHeight: viewMode === "desktop" ? "6vh" : "4vh",
                          justifyContent: "center",
                          alignItems: "center",
                        },
                        children: [
                          {
                            component: "text",
                            text: (
                              <AnimationText
                                phrases={[
                                  "</OCTAL DEV",
                                  "DESENVOLVENDO O FUTURO/>",
                                ]}
                                effectType="cascata"
                                style={{
                                  fontSize:
                                    viewMode === "desktop"
                                      ? Theme.sizes.size_48
                                      : Theme.sizes.size_28,
                                  fontFamily: Theme.fontFamilies.primary,
                                  color: Theme.colors.secondary.regular,
                                  fontWeight: Theme.fontWeight.bold,
                                }}
                              />
                            ),
                          },
                        ],
                      },
                      //textFinish
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: 4,
                        },
                        children: [
                          //objetivo
                          {
                            component: "text",
                            text:
                              viewMode === "desktop"
                                ? "A Octal Dev tem como objetivo a criação e o desenvolvimento de sistemas para atender"
                                : "A Octal Dev tem como objetivo a criação e o desenvolvimento de sistemas para atender empresas e startups",
                            style: {
                              width: "100%",
                              textAlign: "center",
                              fontSize:
                                viewMode === "desktop"
                                  ? Theme.sizes.size_20
                                  : Theme.sizes.size_18,
                              // padding: 2,
                              // lineHeight: 1.2,
                              fontFamily: Theme.fontFamilies.regular,
                              color: Theme.colors.secondary.light,
                            },
                          },
                          //ideias em realidade
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: 4,
                            },
                            children: [
                              {
                                component: "text",
                                text: "empresas e startups.",
                                style: {
                                  display:
                                    viewMode === "desktop" ? "flex" : "none",
                                  textAlign: "center",
                                  fontSize:
                                    viewMode === "desktop"
                                      ? Theme.sizes.size_20
                                      : Theme.sizes.size_18,
                                  fontFamily: Theme.fontFamilies.regular,
                                  color: Theme.colors.secondary.light,
                                },
                              },
                              {
                                component: "text",
                                text: " Transformando ideias em realidade.",
                                style: {
                                  fontSize:
                                    viewMode === "desktop"
                                      ? Theme.sizes.size_20
                                      : Theme.sizes.size_18,
                                  fontFamily: Theme.fontFamilies.primary,
                                  color: Theme.colors.secondary.regular,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      //btn fale com um especialista
                      {
                        component: "container",
                        style: {
                          width: "auto",
                          height: "auto",
                          padding: 4,
                        },
                        children: [
                          Button({
                            style: {
                              button: {
                                width: "auto",
                                height: "auto",
                                border: "1px solid",
                                borderColor: Theme.colors.secondary.regular,
                                borderRadius: 8,
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 8,
                              },
                              label: {
                                fontSize: Theme.sizes.size_12,
                                fontFamily: Theme.fontFamilies.primary,
                                color: Theme.colors.secondary.regular,
                              },
                            },
                            label: "Fale com um especialista",
                            onPress: async () => {
                              alert("olá");
                            },
                          }),
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          //oque orefecemos \/
          {
            component: "container",
            style: {
              display: "flex",
              maxWidth: "100%",
              height: "100vh",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            },
            children: [
              //component box
              SkillContent({
                viewMode: viewMode,
                item: [
                  {
                    title: "Infraestrutura Escalável",
                    image: skillInfra,
                    description:
                      "A infraestrutura da Octal é projetada para crescer conforme as demandas dos clientes, garantindo desempenho e eficiência. Isso possibilita a adaptação rápida em ambientes em nuvem, otimizando recursos durante picos de acesso e aumentando a disponibilidade dos serviços, assegurando ao mesmo tempo a continuidade do negócio e a satisfação do usuário final.",
                  },
                  {
                    title: "Biblioteca e recursos dinâmicos",
                    image: skillLib,
                    description:
                      "A biblioteca da Octal oferece uma ampla gama de funções otimizadas, que facilitam o acesso e a reutilização de código, acelerando o desenvolvimento. O consumo de API é simplificado por soluções personalizadas que se integram perfeitamente a sistemas existentes, permitindo uma comunicação eficiente e suportando um vasto conjunto de integrações para atender às necessidades específicas de cada cliente.",
                  },
                  {
                    title: "Segurança em primeiro lugar",
                    image: skillSecurity,
                    description:
                      "A segurança é uma prioridade na Octal, com protocolos rigorosos que incluem backups regulares, firewalls robustos e ambientes isolados para desenvolvimento e produção. Esta abordagem não só garante a integridade dos dados, mas também protege contra ameaças cibernéticas, oferecendo um controle efetivo sobre o código e os prazos, além de logs para rastreamento e monitoramento contínuo do desenvolvimento.",
                  },
                  {
                    title: "Gestão e desenvolvimento",
                    image: skillDev,
                    description:
                      "A Octal se destaca no desenvolvimento de softwares personalizados em um ambiente colaborativo e escalável em nuvem. Acompanhamos em tempo real as etapas do desenvolvimento, promovendo transparência e flexibilidade essenciais para a gestão ágil de projetos. Asseguramos uma estrutura robusta de gestão com sprints bem definidos e uma comunicação eficiente entre as equipes.",
                  },
                ],
              }),
            ],
          },
          //Comofazemos \/
          {
            component: "container",
            style: {
              display: "flex",
              maxWidth: "100%",
              height: "100vh",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            },
            children: [
              //component box
              InfoContent({
                viewMode: viewMode,
                item: [
                  {
                    title: "1",
                    description:
                      "Acesso a um vasto conjunto de ferramentas e materiais.",
                    style: {
                      opacity: 0.6,
                    },
                  },
                  {
                    title: "2",
                    description:
                      "Desenvolvimento de sistemas sob medida para o seu negócio.",
                    style: {
                      opacity: 0.6,
                    },
                  },
                  {
                    title: "3",
                    description:
                      "Orientação especializada para alavancar seus projetos.",
                    style: {
                      opacity: 0.6,
                    },
                  },
                  {
                    title: "4",
                    description:
                      "Atendimento dedicado para garantir o sucesso contínuo.",
                    style: {
                      pacity: 0.6,
                    },
                  },
                ],
              }),
            ],
          },
          //mapa
          // {
          //   render: (
          //     <Fade top>
          //       {Render({
          //         component: "container",
          //         style: {
          //           display: "flex",
          //           width: "100%",
          //           height: "100%",
          //           flexDirection: "column",
          //           justifyContent: "flex-start",
          //           alignItems: "center",
          //           marginBottom: 128,
          //         },
          //         children: [
          //           //image
          //           {
          //             component: "image",
          //             src: bcgMapa,
          //             style: {
          //               width: viewMode === "desktop" ? "88%" : "100%",
          //               opacity: 0.8,
          //               height: "auto",
          //             },
          //           },
          //           //etapas
          //           {
          //             component: "container",
          //             style: {
          //               display: "flex",
          //               width: viewMode === "desktop" ? "88%" : "100%",
          //               minHeight: "100%",
          //               alignItems: "center",
          //               justifyContent: "flex-start",
          //               flexDirection: "column",
          //               opacity: 0.9,
          //               position: "fixed",
          //             },
          //             children: [
          //               //step 8
          //               {
          //                 component: "container",
          //                 style: {
          //                   position: "absolute",
          //                   top: "32%",
          //                   right: "14%",
          //                 },
          //                 children: [
          //                   Icon({
          //                     src: StepMap8,
          //                     size: viewMode === "desktop" ? "6vw" : "8vw",
          //                     onPress: () => {
          //                       PopupShow({
          //                         style: {
          //                           box: {
          //                             width: 326,
          //                             // backgroundColor: "blue",
          //                           },
          //                           close: {
          //                             display: "flex",
          //                           },
          //                         },
          //                         item: [
          //                           {
          //                             component: "container",
          //                             style: {
          //                               display: "flex",
          //                               width: "100%",
          //                               height: "100%",
          //                               justifyContent: "flex-start",
          //                               alignItems: "center",
          //                               flexDirection: "column",
          //                             },
          //                             children: [
          //                               {
          //                                 component: "image",
          //                                 src: StepMap8,
          //                                 style: {
          //                                   position: "relative",
          //                                   top: -32,
          //                                   width: 72,
          //                                   height: 72,
          //                                 },
          //                               },
          //                               //Title
          //                               {
          //                                 component: "text",
          //                                 text: "CONCLUSÃO MAPA DO TESOURO",
          //                                 style: {
          //                                   width: "100%",
          //                                   textAlign: "center",
          //                                   fontSize: 32,
          //                                   ...Theme.text.primary.title.extra_large(
          //                                     getTheme()
          //                                   ),
          //                                 },
          //                               },
          //                               //description
          //                               {
          //                                 component: "text",
          //                                 text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at dolor nec turpis luctus tincidunt. Sed vulputate, libero sed consectetur malesuada, ligula magna efficitur enim, vel scelerisque felis turpis sed leo. Curabitur fringilla, urna id suscipit ultricies, urna lectus aliquet nunc, sit amet consectetur magna libero et lacus. In ut libero nec nisi malesuada tincidunt. Integer a orci eget libero cursus hendrerit. Nullam id turpis a justo gravida suscipit. Aliquam erat volutpat.",
          //                                 style: {
          //                                   textAlign: "justify",
          //                                   padding: 14,
          //                                   ...Theme.text.primary.text.regular(
          //                                     getTheme()
          //                                   ),
          //                                 },
          //                               },
          //                             ],
          //                           },
          //                         ],
          //                       });
          //                     },
          //                   }),
          //                 ],
          //               },
          //               //step7
          //               {
          //                 component: "container",
          //                 style: {
          //                   position: "absolute",
          //                   top: "2%",
          //                   right: "28%",
          //                 },
          //                 children: [
          //                   Icon({
          //                     src: StepMap7,
          //                     size: viewMode === "desktop" ? "6vw" : "8vw",
          //                     onPress: () => {
          //                       PopupShow({
          //                         style: {
          //                           box: {
          //                             width: 326,
          //                             // backgroundColor: "blue",
          //                           },
          //                           close: {
          //                             display: "flex",
          //                           },
          //                         },
          //                         item: [
          //                           {
          //                             component: "container",
          //                             style: {
          //                               display: "flex",
          //                               width: "100%",
          //                               height: "100%",
          //                               justifyContent: "flex-start",
          //                               alignItems: "center",
          //                               flexDirection: "column",
          //                             },
          //                             children: [
          //                               {
          //                                 component: "image",
          //                                 src: StepMap7,
          //                                 style: {
          //                                   position: "relative",
          //                                   top: -32,
          //                                   width: 72,
          //                                   height: 72,
          //                                 },
          //                               },
          //                               //Title
          //                               {
          //                                 component: "text",
          //                                 text: "ILHA DO LANÇAMENTO",
          //                                 style: {
          //                                   width: "100%",
          //                                   textAlign: "center",
          //                                   fontSize: 32,
          //                                   ...Theme.text.primary.title.extra_large(
          //                                     getTheme()
          //                                   ),
          //                                 },
          //                               },
          //                               //description
          //                               {
          //                                 component: "text",
          //                                 text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at dolor nec turpis luctus tincidunt. Sed vulputate, libero sed consectetur malesuada, ligula magna efficitur enim, vel scelerisque felis turpis sed leo. Curabitur fringilla, urna id suscipit ultricies, urna lectus aliquet nunc, sit amet consectetur magna libero et lacus. In ut libero nec nisi malesuada tincidunt. Integer a orci eget libero cursus hendrerit. Nullam id turpis a justo gravida suscipit. Aliquam erat volutpat.",
          //                                 style: {
          //                                   textAlign: "justify",
          //                                   padding: 14,
          //                                   ...Theme.text.primary.text.regular(
          //                                     getTheme()
          //                                   ),
          //                                 },
          //                               },
          //                             ],
          //                           },
          //                         ],
          //                       });
          //                     },
          //                   }),
          //                 ],
          //               },
          //               //step6
          //               {
          //                 component: "container",
          //                 style: {
          //                   position: "absolute",
          //                   top: "18%",
          //                   right: "38%",
          //                 },
          //                 children: [
          //                   Icon({
          //                     src: StepMap6,
          //                     size: viewMode === "desktop" ? "6vw" : "8vw",
          //                     onPress: () => {
          //                       PopupShow({
          //                         style: {
          //                           box: {
          //                             width: 326,
          //                             // backgroundColor: "blue",
          //                           },
          //                           close: {
          //                             display: "flex",
          //                           },
          //                         },
          //                         item: [
          //                           {
          //                             component: "container",
          //                             style: {
          //                               display: "flex",
          //                               width: "100%",
          //                               height: "100%",
          //                               justifyContent: "flex-start",
          //                               alignItems: "center",
          //                               flexDirection: "column",
          //                             },
          //                             children: [
          //                               {
          //                                 component: "image",
          //                                 src: StepMap6,
          //                                 style: {
          //                                   position: "relative",
          //                                   top: -32,
          //                                   width: 72,
          //                                   height: 72,
          //                                 },
          //                               },
          //                               //Title
          //                               {
          //                                 component: "text",
          //                                 text: "PÂNTANO DOS TESTES",
          //                                 style: {
          //                                   width: "100%",
          //                                   textAlign: "center",
          //                                   fontSize: 32,
          //                                   ...Theme.text.primary.title.extra_large(
          //                                     getTheme()
          //                                   ),
          //                                 },
          //                               },
          //                               //description
          //                               {
          //                                 component: "text",
          //                                 text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at dolor nec turpis luctus tincidunt. Sed vulputate, libero sed consectetur malesuada, ligula magna efficitur enim, vel scelerisque felis turpis sed leo. Curabitur fringilla, urna id suscipit ultricies, urna lectus aliquet nunc, sit amet consectetur magna libero et lacus. In ut libero nec nisi malesuada tincidunt. Integer a orci eget libero cursus hendrerit. Nullam id turpis a justo gravida suscipit. Aliquam erat volutpat.",
          //                                 style: {
          //                                   textAlign: "justify",
          //                                   padding: 14,
          //                                   ...Theme.text.primary.text.regular(
          //                                     getTheme()
          //                                   ),
          //                                 },
          //                               },
          //                             ],
          //                           },
          //                         ],
          //                       });
          //                     },
          //                   }),
          //                 ],
          //               },
          //               //step5
          //               {
          //                 component: "container",
          //                 style: {
          //                   position: "absolute",
          //                   top: "42%",
          //                   left: "32%",
          //                 },
          //                 children: [
          //                   Icon({
          //                     src: StepMap5,
          //                     size: viewMode === "desktop" ? "6vw" : "8vw",
          //                     style: {
          //                       image: {
          //                         height: "auto",
          //                       },
          //                     },
          //                     onPress: () => {
          //                       PopupShow({
          //                         style: {
          //                           box: {
          //                             width: 326,
          //                             // backgroundColor: "blue",
          //                           },
          //                           close: {
          //                             display: "flex",
          //                           },
          //                         },
          //                         item: [
          //                           {
          //                             component: "container",
          //                             style: {
          //                               display: "flex",
          //                               width: "100%",
          //                               height: "100%",
          //                               justifyContent: "flex-start",
          //                               alignItems: "center",
          //                               flexDirection: "column",
          //                             },
          //                             children: [
          //                               {
          //                                 component: "image",
          //                                 src: StepMap5,
          //                                 style: {
          //                                   position: "relative",
          //                                   top: -32,
          //                                   width: 92,
          //                                   height: 62,
          //                                 },
          //                               },
          //                               //Title
          //                               {
          //                                 component: "text",
          //                                 text: "VALE DO DESENVOLVIMENTO",
          //                                 style: {
          //                                   width: "100%",
          //                                   textAlign: "center",
          //                                   fontSize: 32,
          //                                   ...Theme.text.primary.title.extra_large(
          //                                     getTheme()
          //                                   ),
          //                                 },
          //                               },
          //                               //description
          //                               {
          //                                 component: "text",
          //                                 text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at dolor nec turpis luctus tincidunt. Sed vulputate, libero sed consectetur malesuada, ligula magna efficitur enim, vel scelerisque felis turpis sed leo. Curabitur fringilla, urna id suscipit ultricies, urna lectus aliquet nunc, sit amet consectetur magna libero et lacus. In ut libero nec nisi malesuada tincidunt. Integer a orci eget libero cursus hendrerit. Nullam id turpis a justo gravida suscipit. Aliquam erat volutpat.",
          //                                 style: {
          //                                   textAlign: "justify",
          //                                   padding: 14,
          //                                   ...Theme.text.primary.text.regular(
          //                                     getTheme()
          //                                   ),
          //                                 },
          //                               },
          //                             ],
          //                           },
          //                         ],
          //                       });
          //                     },
          //                   }),
          //                 ],
          //               },
          //               //step4
          //               {
          //                 component: "container",
          //                 style: {
          //                   position: "absolute",
          //                   bottom: "44%",
          //                   right: "34%",
          //                 },
          //                 children: [
          //                   Icon({
          //                     src: StepMap4,
          //                     size: viewMode === "desktop" ? "6vw" : "8vw",
          //                     onPress: () => {
          //                       PopupShow({
          //                         style: {
          //                           box: {
          //                             width: 326,
          //                             // backgroundColor: "blue",
          //                           },
          //                           close: {
          //                             display: "flex",
          //                           },
          //                         },
          //                         item: [
          //                           {
          //                             component: "container",
          //                             style: {
          //                               display: "flex",
          //                               width: "100%",
          //                               height: "100%",
          //                               justifyContent: "flex-start",
          //                               alignItems: "center",
          //                               flexDirection: "column",
          //                             },
          //                             children: [
          //                               {
          //                                 component: "image",
          //                                 src: StepMap4,
          //                                 style: {
          //                                   position: "relative",
          //                                   top: -32,
          //                                   width: 72,
          //                                   height: 72,
          //                                 },
          //                               },
          //                               //Title
          //                               {
          //                                 component: "text",
          //                                 text: "MONTANHA DE ESTRUTURAÇÃO",
          //                                 style: {
          //                                   width: "100%",
          //                                   textAlign: "center",
          //                                   fontSize: 32,
          //                                   ...Theme.text.primary.title.extra_large(
          //                                     getTheme()
          //                                   ),
          //                                 },
          //                               },
          //                               //description
          //                               {
          //                                 component: "text",
          //                                 text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at dolor nec turpis luctus tincidunt. Sed vulputate, libero sed consectetur malesuada, ligula magna efficitur enim, vel scelerisque felis turpis sed leo. Curabitur fringilla, urna id suscipit ultricies, urna lectus aliquet nunc, sit amet consectetur magna libero et lacus. In ut libero nec nisi malesuada tincidunt. Integer a orci eget libero cursus hendrerit. Nullam id turpis a justo gravida suscipit. Aliquam erat volutpat.",
          //                                 style: {
          //                                   textAlign: "justify",
          //                                   padding: 14,
          //                                   ...Theme.text.primary.text.regular(
          //                                     getTheme()
          //                                   ),
          //                                 },
          //                               },
          //                             ],
          //                           },
          //                         ],
          //                       });
          //                     },
          //                   }),
          //                 ],
          //               },
          //               //step3
          //               {
          //                 component: "container",
          //                 style: {
          //                   position: "absolute",
          //                   bottom: "22%",
          //                   left: "42%",
          //                 },
          //                 children: [
          //                   Icon({
          //                     src: StepMap3,
          //                     size: viewMode === "desktop" ? "6vw" : "8vw",
          //                     onPress: () => {
          //                       PopupShow({
          //                         style: {
          //                           box: {
          //                             width: 326,
          //                             // backgroundColor: "blue",
          //                           },
          //                           close: {
          //                             display: "flex",
          //                           },
          //                         },
          //                         item: [
          //                           {
          //                             component: "container",
          //                             style: {
          //                               display: "flex",
          //                               width: "100%",
          //                               height: "100%",
          //                               justifyContent: "flex-start",
          //                               alignItems: "center",
          //                               flexDirection: "column",
          //                             },
          //                             children: [
          //                               {
          //                                 component: "image",
          //                                 src: StepMap3,
          //                                 style: {
          //                                   position: "relative",
          //                                   top: -32,
          //                                   width: 72,
          //                                   height: 72,
          //                                 },
          //                               },
          //                               //Title
          //                               {
          //                                 component: "text",
          //                                 text: "CAVERNA DOS PROPÓTIPOS",
          //                                 style: {
          //                                   width: "100%",
          //                                   textAlign: "center",
          //                                   fontSize: 32,
          //                                   ...Theme.text.primary.title.extra_large(
          //                                     getTheme()
          //                                   ),
          //                                 },
          //                               },
          //                               //description
          //                               {
          //                                 component: "text",
          //                                 text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at dolor nec turpis luctus tincidunt. Sed vulputate, libero sed consectetur malesuada, ligula magna efficitur enim, vel scelerisque felis turpis sed leo. Curabitur fringilla, urna id suscipit ultricies, urna lectus aliquet nunc, sit amet consectetur magna libero et lacus. In ut libero nec nisi malesuada tincidunt. Integer a orci eget libero cursus hendrerit. Nullam id turpis a justo gravida suscipit. Aliquam erat volutpat.",
          //                                 style: {
          //                                   textAlign: "justify",
          //                                   padding: 14,
          //                                   ...Theme.text.primary.text.regular(
          //                                     getTheme()
          //                                   ),
          //                                 },
          //                               },
          //                             ],
          //                           },
          //                         ],
          //                       });
          //                     },
          //                   }),
          //                 ],
          //               },
          //               //step2
          //               {
          //                 component: "container",
          //                 style: {
          //                   position: "absolute",
          //                   bottom: "22%",
          //                   right: "32%",
          //                 },
          //                 children: [
          //                   Icon({
          //                     src: StepMap2,
          //                     size: viewMode === "desktop" ? "6vw" : "8vw",
          //                     onPress: () => {
          //                       PopupShow({
          //                         style: {
          //                           box: {
          //                             width: 326,
          //                             // backgroundColor: "blue",
          //                           },
          //                           close: {
          //                             display: "flex",
          //                           },
          //                         },
          //                         item: [
          //                           {
          //                             component: "container",
          //                             style: {
          //                               display: "flex",
          //                               width: "100%",
          //                               height: "100%",
          //                               justifyContent: "flex-start",
          //                               alignItems: "center",
          //                               flexDirection: "column",
          //                             },
          //                             children: [
          //                               {
          //                                 component: "image",
          //                                 src: StepMap2,
          //                                 style: {
          //                                   position: "relative",
          //                                   top: -32,
          //                                   width: 72,
          //                                   height: 72,
          //                                 },
          //                               },
          //                               //Title
          //                               {
          //                                 component: "text",
          //                                 text: "PORTO DAS IDÉIAS",
          //                                 style: {
          //                                   width: "100%",
          //                                   textAlign: "center",
          //                                   fontSize: 32,
          //                                   ...Theme.text.primary.title.extra_large(
          //                                     getTheme()
          //                                   ),
          //                                 },
          //                               },
          //                               //description
          //                               {
          //                                 component: "text",
          //                                 text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at dolor nec turpis luctus tincidunt. Sed vulputate, libero sed consectetur malesuada, ligula magna efficitur enim, vel scelerisque felis turpis sed leo. Curabitur fringilla, urna id suscipit ultricies, urna lectus aliquet nunc, sit amet consectetur magna libero et lacus. In ut libero nec nisi malesuada tincidunt. Integer a orci eget libero cursus hendrerit. Nullam id turpis a justo gravida suscipit. Aliquam erat volutpat.",
          //                                 style: {
          //                                   textAlign: "justify",
          //                                   padding: 14,
          //                                   ...Theme.text.primary.text.regular(
          //                                     getTheme()
          //                                   ),
          //                                 },
          //                               },
          //                             ],
          //                           },
          //                         ],
          //                       });
          //                     },
          //                   }),
          //                 ],
          //               },
          //               //step 1
          //               {
          //                 component: "container",
          //                 style: {
          //                   position: "absolute",
          //                   bottom: "16%",
          //                   right: "16%",
          //                 },
          //                 children: [
          //                   Icon({
          //                     src: StepMap1,
          //                     size: viewMode === "desktop" ? "6vw" : "8vw",
          //                     onPress: () => {
          //                       PopupShow({
          //                         style: {
          //                           box: {
          //                             width: 326,
          //                             // backgroundColor: "blue",
          //                           },
          //                           close: {
          //                             display: "flex",
          //                           },
          //                         },
          //                         item: [
          //                           {
          //                             component: "container",
          //                             style: {
          //                               display: "flex",
          //                               width: "100%",
          //                               height: "100%",
          //                               justifyContent: "flex-start",
          //                               alignItems: "center",
          //                               flexDirection: "column",
          //                             },
          //                             children: [
          //                               {
          //                                 component: "image",
          //                                 src: StepMap1,
          //                                 style: {
          //                                   position: "relative",
          //                                   top: -32,
          //                                   width: 72,
          //                                   height: 72,
          //                                 },
          //                               },
          //                               //Title
          //                               {
          //                                 component: "text",
          //                                 text: "ILHA DO PROPÓSITO",
          //                                 style: {
          //                                   width: "100%",
          //                                   textAlign: "center",
          //                                   fontSize: 32,
          //                                   ...Theme.text.primary.title.extra_large(
          //                                     getTheme()
          //                                   ),
          //                                 },
          //                               },
          //                               //description
          //                               {
          //                                 component: "text",
          //                                 text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at dolor nec turpis luctus tincidunt. Sed vulputate, libero sed consectetur malesuada, ligula magna efficitur enim, vel scelerisque felis turpis sed leo. Curabitur fringilla, urna id suscipit ultricies, urna lectus aliquet nunc, sit amet consectetur magna libero et lacus. In ut libero nec nisi malesuada tincidunt. Integer a orci eget libero cursus hendrerit. Nullam id turpis a justo gravida suscipit. Aliquam erat volutpat.",
          //                                 style: {
          //                                   textAlign: "justify",
          //                                   padding: 14,
          //                                   ...Theme.text.primary.text.regular(
          //                                     getTheme()
          //                                   ),
          //                                 },
          //                               },
          //                             ],
          //                           },
          //                         ],
          //                       });
          //                     },
          //                   }),
          //                 ],
          //               },
          //             ],
          //           },
          //         ],
          //       })}
          //     </Fade>
          //   ),
          // },
          //Dev para dev \/
          {
            component: "container",
            style: {
              display: "flex",
              maxWidth: "100%",
              minHeight: "100vh",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            },
            children: [
              CardInfo({
                viewMode: viewMode,
                item: [
                  {
                    title: "Cultura de trabalho",
                    children: [
                      {
                        description:
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at dolor nec turpis luctus tincidunt. Sed vulputate, libero sed consectetur malesuada, ligula magna efficitur enim, vel scelerisque felis turpis sed leo. Curabitur fringilla, urna id suscipit ultricies, urna lectus aliquet nunc, sit amet consectetur magna libero et lacus. In ut libero nec nisi malesuada tincidunt. Integer a orci eget libero cursus hendrerit. Nullam id turpis a justo gravida suscipit. Aliquam erat volutpat.",
                      },
                    ],
                  },
                  {
                    title: "Desenvolvimento colaborativo",
                    children: [
                      {
                        description:
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at dolor nec turpis luctus tincidunt. Sed vulputate, libero sed consectetur malesuada, ligula magna efficitur enim, vel scelerisque felis turpis sed leo. Curabitur fringilla, urna id suscipit ultricies, urna lectus aliquet nunc, sit amet consectetur magna libero et lacus. In ut libero nec nisi malesuada tincidunt. Integer a orci eget libero cursus hendrerit. Nullam id turpis a justo gravida suscipit. Aliquam erat volutpat.",
                      },
                    ],
                  },
                  {
                    title: "Segurança do começo ao fim",
                    children: [
                      {
                        description:
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at dolor nec turpis luctus tincidunt. Sed vulputate, libero sed consectetur malesuada, ligula magna efficitur enim, vel scelerisque felis turpis sed leo. Curabitur fringilla, urna id suscipit ultricies, urna lectus aliquet nunc, sit amet consectetur magna libero et lacus. In ut libero nec nisi malesuada tincidunt. Integer a orci eget libero cursus hendrerit. Nullam id turpis a justo gravida suscipit. Aliquam erat volutpat.",
                      },
                    ],
                  },
                  {
                    title: "Acompanhamento em tempo real",
                    children: [
                      {
                        description:
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin at dolor nec turpis luctus tincidunt. Sed vulputate, libero sed consectetur malesuada, ligula magna efficitur enim, vel scelerisque felis turpis sed leo. Curabitur fringilla, urna id suscipit ultricies, urna lectus aliquet nunc, sit amet consectetur magna libero et lacus. In ut libero nec nisi malesuada tincidunt. Integer a orci eget libero cursus hendrerit. Nullam id turpis a justo gravida suscipit. Aliquam erat volutpat.",
                      },
                    ],
                  },
                ],
              }),
            ],
          },
          //resultado
          {
            component: "container",
            style: {
              display: "flex",
              width: "100%",
              minHeight: "100vh",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 42,
            },
            children: [
              // Text info
              {
                component: "container",
                style: {
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: Theme.sizes.size_12,
                },
                children: [
                  //title
                  {
                    component: "text",
                    text: "NOSSAS CONQUISTAS",
                    style: {
                      color: Theme.colors.primary.extra_ligth,
                      fontSize:
                        viewMode === "desktop"
                          ? Theme.sizes.size_48
                          : Theme.sizes.size_28,
                      fontFamily: Theme.fontFamilies.primary,
                      transition: "color 0.3s ease, transform 0.3s ease",
                    },
                  },
                  //descrição
                  {
                    component: "text",
                    text: "Projetos que fazem parte da nossa história",
                    style: {
                      color: Theme.colors.primary.extra_ligth,
                      fontSize:
                        viewMode === "desktop"
                          ? Theme.sizes.size_18
                          : Theme.sizes.size_16,
                      fontFamily: Theme.fontFamilies.regular,
                      textAlign: "center",
                      transition: "color 0.3s ease, transform 0.3s ease",
                      color: Theme.colors.secondary.medium,
                    },
                  },
                ],
              },
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "auto",
                  flexDirection: viewMode === "desktop" ? "row" : "column",
                  paddingLeft: viewMode === "desktop" ? "8%" : 0,
                  gap: 12,
                },
                children: [
                  //navBar
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      height: viewMode === "desktop" ? "100%" : "20%",
                      width: viewMode === "desktop" ? "30%" : "100%",
                    },
                    children: [
                      CardView({
                        infoIntro: "Petin",
                        style: {
                          boxMain: {
                            flexDirection:
                              viewMode === "desktop" ? "column" : "row",
                            width: "auto",
                          },
                        },
                        item: [
                          //PETIN
                          {
                            page: "Petin",
                            title: "Petin digital",
                            image: {
                              src: PetinIcon,
                            },
                            style: {
                              iconBox: {
                                display:
                                  viewMode === "desktop" ? "none" : "flex",
                              },
                              title: {
                                display:
                                  viewMode === "desktop" ? "flex" : "none",
                              },
                            },
                            onClick: (page) => {
                              setContentShow(page);
                            },
                          },
                          //HORTA
                          {
                            page: "Horta",
                            title: "Da hora pra horta",
                            image: {
                              src: HortaIcon,
                            },
                            style: {
                              iconBox: {
                                display:
                                  viewMode === "desktop" ? "none" : "flex",
                              },
                              title: {
                                display:
                                  viewMode === "desktop" ? "flex" : "none",
                              },
                            },
                            onClick: (page) => {
                              setContentShow(page);
                            },
                          },
                          //DREAM
                          {
                            page: "Dream",
                            title: "Dream Buffets",
                            image: {
                              src: DreamIcon,
                            },
                            style: {
                              iconBox: {
                                display:
                                  viewMode === "desktop" ? "none" : "flex",
                              },
                              title: {
                                display:
                                  viewMode === "desktop" ? "flex" : "none",
                              },
                            },
                            onClick: (page) => {
                              setContentShow(page);
                            },
                          },
                        ],
                      }),
                    ],
                  },
                  //content
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      height: "100%",
                      maxWidth: "auto",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 2,
                    },
                    children: [
                      Petin({ viewMode, ContentShow }),
                      Horta({ viewMode, ContentShow }),
                      Dream({ viewMode, ContentShow }),
                    ],
                  },
                ],
              },
            ],
          },
          //fale conosco
          {
            component: "container",
            style: {
              display: "flex",
              width: "100%",
              minHeight: "auto",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 32,
              // backgroundImage: `url(${bcgBubbles})`,
              // backgroundRepeat: "no-repeat",
              // backgroundSize: "456px 456px",
              // backgroundPosition: "top 72% right 8%",
            },
            children: [
              // Text info
              {
                component: "container",
                style: {
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: Theme.sizes.size_12,
                },
                children: [
                  //title
                  {
                    component: "text",
                    text: "FALE CONOSCO",
                    style: {
                      color: Theme.colors.primary.extra_ligth,
                      fontSize:
                        viewMode === "desktop"
                          ? Theme.sizes.size_48
                          : Theme.sizes.size_28,
                      fontFamily: Theme.fontFamilies.primary,
                      transition: "color 0.3s ease, transform 0.3s ease",
                    },
                  },
                  //descrição
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      flexDirection: viewMode === "desktop" ? "row" : "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 4,
                    },
                    children: [
                      //titulo do assunto
                      {
                        component: "text",
                        text: "Se identificou com seus objetivos?",
                        style: {
                          color: Theme.colors.primary.extra_ligth,
                          fontSize: Theme.sizes.size_18,
                          fontFamily: Theme.fontFamilies.regular,
                          textAlign: "center",
                          padding:
                            viewMode === "desktop" ? 0 : Theme.sizes.size_4,
                          transition: "color 0.3s ease, transform 0.3s ease",
                        },
                      },
                      //titulo do assunto
                      {
                        component: "text",
                        text: "a gente te ajuda!",
                        style: {
                          color: Theme.colors.secondary.medium,
                          fontSize: Theme.sizes.size_18,
                          fontFamily: Theme.fontFamilies.regular,
                        },
                      },
                    ],
                  },
                ],
              },
              //form
              {
                component: "container",
                style: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: viewMode === "desktop" ? "62vh" : "92%",
                  height: "auto",
                  padding: 12,
                  gap: 12,
                },
                children: [
                  inputName,
                  inputEmail,
                  inputPhone,
                  inputMessage,
                  //btnSend
                  {
                    component: "container",
                    style: {
                      marginTop: 12,
                      width: 256,
                      height: 42,
                    },
                    children: [
                      Button({
                        style: {
                          button: {
                            width: "100%",
                            height: "100%",
                            backgroundColor: Theme.colors.secondary.regular,
                            border: "none",
                            borderRadius: 8,
                          },
                          label: {
                            fontSize: 24,
                            fontFamily: "Poppins",
                            color: Theme.colors.primary.regular,
                          },
                        },
                        label: "Enviar",
                        onPress: () => {
                          console.log(name, email, phone, message);
                        },
                      }),
                    ],
                  },
                ],
              },
            ],
          },
          //bcg-octopus + footer
          {
            component: "container",
            style: {
              display: "flex",
              maxWidth: "100%",
              height: "54vh",
              alignItems: "flex-end",
              justifyContent: "center",
              padding: 12,
              backgroundImage: `url(${bcgOctopusFooter})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition:
                viewMode === "desktop" ? "center 10px" : "bottom",
            },
            children: [
              {
                component: "container",
                style: {
                  display: "flex",
                  width: "100%",
                  height: "auto",
                  flexDirection: "column",
                },
                children: [
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      padding: 12,
                      gap: 12,
                    },
                    children: [
                      //image octal
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "auto",
                          height: "auto",
                          gap: 8,
                        },
                        children: [
                          //image
                          {
                            component: "image",
                            src: LogoOctalFooter,
                            style: {
                              width: 32,
                              height: 32,
                            },
                          },
                          //text
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              flexDirection: "row",
                              height: "auto",
                              width: "auto",
                              alignItems: "flex-end",
                            },
                            children: [
                              {
                                component: "text",
                                text: "<octal>",
                                style: {
                                  fontFamily: Theme.fontFamilies.primary,
                                  fontWeight: "bold",
                                  fontSize: 18,
                                  color: "white",
                                },
                              },
                              {
                                component: "text",
                                text: ".dev",
                                style: {
                                  fontFamily: Theme.fontFamilies.primary,
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  color: "white",
                                },
                              },
                            ],
                          },
                        ],
                      },
                      //links
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "auto",
                          height: "auto",
                          gap: 8,
                        },
                        children: [
                          Icon({
                            src: IconInstagram,
                            srcHover: "",
                            size: Theme.sizes.size_24,
                            style: {
                              iconBox: {
                                width: "auto",
                              },
                            },
                            onPress: () => {
                              console.log("oi");
                            },
                          }),
                          Icon({
                            src: IconFacebook,
                            srcHover: "",
                            size: Theme.sizes.size_24,
                            style: {
                              iconBox: {
                                width: "auto",
                              },
                            },
                            onPress: () => {},
                          }),
                          Icon({
                            src: IconWhatsapp,
                            srcHover: "",
                            size: Theme.sizes.size_24,
                            style: {
                              iconBox: {
                                width: "auto",
                              },
                            },
                            onPress: () => {},
                          }),
                          Icon({
                            src: IconLinkedin,
                            srcHover: "",
                            size: Theme.sizes.size_24,
                            style: {
                              iconBox: {
                                width: "auto",
                              },
                            },
                            onPress: () => {},
                          }),
                        ],
                      },
                    ],
                  },
                  //direitos
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      width: "auto",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      flexDirection: "column",
                    },
                    children: [
                      //text
                      {
                        component: "text",
                        text: "© Todos os direitos reservados a OctalDev.",
                        style: {
                          fontStyle: "italic",
                          fontSize: viewMode === "desktop" ? "0.66vw" : "1vh",
                          fontFamily: "Poppins",
                          color: "#6A6A6A",
                        },
                      },
                      //text
                      {
                        component: "text",
                        text: "OCTOPUS TECNOLOGIA LTDA  - 45.644.187/0001-02",
                        style: {
                          fontStyle: "italic",
                          fontSize: viewMode === "desktop" ? "0.66vw" : "1vh",
                          fontFamily: "Poppins",
                          color: "#6A6A6A",
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
};
