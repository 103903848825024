import Theme from "theme";
import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
let state = {};

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export default (props) => {
  const thisID = props.id || Object.keys(state).length;
  if (!state[thisID]) state[thisID] = { focus: false };

  let stylePlaceholder = {};
  let placeholderShow = true;
  let styleTitle = {};
  let styleInput = {};

  if (props?.style?.title) {
    styleTitle = props.style.title;
    delete props.style.title;
  }
  if (props?.style?.input) {
    styleInput = props.style.input;
    delete props.style.input;
  }

  const [inptValue, setInptValue] = useState(props.value);

  useEffect(async () => {
    setInptValue("");
    if (props.setValue) props.setValue("");
    if (props.set) {
      if (props.keyid !== undefined) props.set({ k: props.keyid, v: "" });
      else props.set({ k: props.id, v: "" });
    }
  }, [props.clear]);

  useEffect(async () => {
    if (props.value === undefined) setInptValue("");
    else setInptValue(props.value);
  }, [props.value]);

  let inpt = {
    ...props,
    focus: false,
    error: false,
  };

  return {
    component: "container",
    style: {
      backgroundColor: "rgba(220,230,250,0)",
      display: "flex",
      maxWidth: "100%",
      overflow: "hidden",
      flexDirection: "column",
      alignItems: "space-between",
      justifyContent: "space-between",
      width: "100%",
      backgroundColor: "transparent",
      overflow: "hidden",
      ...inpt.style,
    },
    children: [
      {
        component: "text",
        style: {
          display: inpt.title ? "flex" : "none",
          marginTop: 4,
          marginBottom: 2,
          flex: 1,
          width: "100%",
          backgroundColor: "transparent",
          ...styleTitle,
        },
        text: inpt.title || "",
      },
      {
        render: (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              overflow: "hidden",
              backgroundColor: "transparent",
              border: "none",
              outlineStyle: "none",
              // ...styleInput,
            }}
          >
            <InputMask
              value={inptValue}
              mask={inpt.mask || ""}
              type={inpt.type || ""}
              style={{
                minHeight: "100%",
                flex: 1,
                outlineStyle: "none",
                backgroundColor: "transparent",
                color: "white",
                border: "none",
                ...styleInput,
              }}
              onFocus={async (e) => {
                state[thisID].focus = true;
              }}
              onBlur={async (e) => {
                state[thisID].focus = false;
                // inptValue = "";
                // console.log(inptValue);
                // this.refs.inputElement.value = "";
              }}
              onChange={async (text) => {
                // retirar mascara
                let value = inpt.mask
                  ? text.target.value.replace(/\D/g, "")
                  : text.target.value;
                setInptValue(value);
                if (props.setValue) props.setValue(value);
                if (props.set) {
                  if (props.keyid !== undefined)
                    props.set({ k: props.keyid, v: value });
                  else props.set({ k: props.id, v: value });
                }
              }}
              placeholder={props.placeholder}
              disabled={props.disabled || false}
            />
          </div>
        ),
      },
    ],
  };
};
