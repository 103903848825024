import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import Icon from "components/web/icon";
import textButton from "components/web/textButton";
import { ShowPopup } from "context/popup";

export default (props) => {
  const [btnSelected, setBtnSelected] = useState([props?.infoIntro]);

  // const handleToggle = (index) => {
  //   setExpandedCards((prevExpandedCards) =>
  //     prevExpandedCards.includes(index)
  //       ? prevExpandedCards.filter((i) => i !== index)
  //       : [...prevExpandedCards, index]
  //   );
  // };

  return {
    component: "container",
    style: {
      width: "100%",
      height: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      borderRadius: 8,
      transition: "height 0.5s ease",
      gap: 8,
    },
    children: [
      //cardMain
      {
        component: "container",
        style: {
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 6,
          gap: 4,
          transition: "max-height 0.5s ease",
          ...props?.style?.boxMain,
        },
        children: props?.item?.map((menu) => {
          // const isExpanded = expandedCards.includes(index);
          return {
            component: "container",
            style: {
              width: "100%",
              height: "12vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              cursor: "pointer",
              transition: "max-height 0.5s ease",
              ...props?.style?.boxMenu,
            },
            children: [
              {
                component: "container",
                style: {
                  width: "auto",
                  height: "62%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 2,
                  flexDirection: "column",
                  flexDirection: "row",
                  transition: "max-height 0.5s ease",
                  gap: 12,
                },
                children: [
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      minWidth: 12,
                      minHeight: 12,
                      opacity: btnSelected.includes(menu.page) ? 1 : 0,
                      backgroundColor: "#00FFC3",
                      borderRadius: "50%",
                      transition: "background-color 0.5s ease-in-out",
                    },
                  },
                  {
                    component: "image",
                    src: menu?.image?.src,
                    style: {
                      width: 42,
                      height: 42,
                      ...menu?.style?.iconBox,
                    },
                    onClick: () => {
                      setBtnSelected([menu.page]);
                      // if (menu.children?.length > 0) setMenuOpen(true);
                      if (menu.onClick)
                        try {
                          menu.onClick(menu.page);
                        } catch (e) {}
                      else {
                        // GoPage(menu.page);
                      }
                    },
                  },
                  textButton({
                    title: menu?.title,
                    style: {
                      title: {
                        ...menu?.style?.title,
                      },
                      titleColorDefault: "white",
                      titleColorHover: "#00FFC3",
                    },
                    onClick: () => {
                      setBtnSelected([menu.page]);
                      // if (menu.children?.length > 0) setMenuOpen(true);
                      if (menu.onClick)
                        try {
                          menu.onClick(menu.page);
                        } catch (e) {}
                      else {
                        // GoPage(menu.page);
                      }
                    },
                  }),
                ],
              },
            ],
          };
        }),
      },
    ],
  };
};
