import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import Icon from "components/web/icon";
import Theme from "theme";

import SlideArrow from "assets/images/menu/icon-slide-arrow.svg";

export default (props) => {
  const [selectedIndex, setSelectedIndex] = useState(1); // Central index
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { item } = props;
  const viewMode = props?.viewMode || "desktop";
  const itemsPerPage = viewMode === "mobile" ? 1 : 3;

  const getVisibleItems = () => {
    const startIndex =
      selectedIndex - 1 >= 0 ? selectedIndex - 1 : item.length - 1;
    const endIndex = (startIndex + itemsPerPage) % item.length;
    return item
      .slice(startIndex, startIndex + itemsPerPage)
      .concat(endIndex < startIndex ? item.slice(0, endIndex) : []);
  };

  const handleNext = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setSelectedIndex((prevIndex) => (prevIndex + 1) % item.length);
    }
  };

  const handlePrev = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setSelectedIndex(
        (prevIndex) => (prevIndex - 1 + item.length) % item.length
      );
    }
  };

  // Reset transition state after animation
  useEffect(() => {
    const timeout = setTimeout(() => setIsTransitioning(false), 500); // Match transition duration
    return () => clearTimeout(timeout);
  }, [selectedIndex]);

  return {
    render: (
      <Fade top>
        {Render({
          component: "container",
          style: {
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            transition: "min-height 0.5s ease, transform 0.5s ease",
            paddingTop: Theme.sizes.size_18,
          },
          children: [
            //o que fazemos
            {
              component: "text",
              text: "COMO DESENVOLVEMOS",
              style: {
                color: Theme.colors.primary.extra_ligth,
                fontSize: Theme.sizes.size_20,
                fontFamily: Theme.fontFamilies.primary,
              },
            },
            // Navegação de Cards
            {
              component: "container",
              style: {
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              },
              children: [
                //button
                {
                  component: "container",
                  style: {
                    position: "absolute",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: viewMode === "desktop" ? 922 : "94%",
                    height: 192,
                  },
                  children: [
                    //back
                    Icon({
                      src: SlideArrow,
                      size: Theme.sizes.size_32,
                      style: {
                        iconBox: {
                          zIndex: 1000,
                        },
                        image: {
                          transform: "rotate(-180deg)",
                        },
                      },
                      onPress: handlePrev,
                    }),
                    //next
                    Icon({
                      src: SlideArrow,
                      size: Theme.sizes.size_32,
                      style: {
                        iconBox: {
                          zIndex: 1000,
                        },
                        image: {
                          cursor: "pointer",
                        },
                      },
                      onPress: handleNext,
                    }),
                  ],
                },
                // Cards
                {
                  component: "container",
                  style: {
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    padding: Theme.sizes.size_8,
                    transition: "min-height 0.5s ease, transform 0.5s ease",
                  },
                  children: getVisibleItems().map((card, index) => {
                    const isSelected = index === 1; // Card central
                    const translateX =
                      (index - 1) * (viewMode === "desktop" ? 900 : 300);
                    return {
                      component: "container",
                      style: {
                        width: viewMode === "desktop" ? 772 : "92vw",
                        height: 400,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: Theme.sizes.size_10,
                        boxShadow: isSelected
                          ? "0px 4px 12px rgba(0, 0, 0, 0.2)"
                          : "none",
                        transform: isSelected ? "scale(1.1)" : "scale(1)",
                        transition: "all 0.5s ease",
                        ...card?.style,
                        margin: viewMode === "desktop" ? 44 : 0,
                        border: "1px solid gray",
                      },
                      children: [
                        {
                          component: "text",
                          text: card.title,
                          style: {
                            color: Theme.colors.primary.extra_light,
                            fontSize: Theme.sizes.size_20,
                          },
                        },
                      ],
                    };
                  }),
                },
              ],
            },
          ],
        })}
      </Fade>
    ),
  };
};
