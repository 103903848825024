import { Render } from "@8_dev/sjson-web";
import { Children, useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { ShowPage } from "context/showPage";

import Theme, { setTheme, getTheme } from "theme";

//components
import Icon from "components/web/icon";
import TextButton from "components/web/textButton";

//images/icons
import bcgSeaDark from "assets/images/bcg/bg-sea-dark.svg";
import bcgStar_2 from "assets/images/bcg/stars_2.svg";
import menuMoon from "assets/images/bcg/moon.svg";
import logoDark from "assets/images/logo/icon-octal-dark.png";
import logoLight from "assets/images/logo/icon-octal-light.png";
import iconSunDark from "assets/images/menu/icon-sun-menu-dark.svg";
import iconSunLigth from "assets/images/menu/icon-sun-menu-light.svg";
import iconMoonDark from "assets/images/menu/icon-moon-menu-dark.svg";
import iconMoonLight from "assets/images/menu/icon-moon-menu-light.svg";

export default (props) => {
  const [backgroundOpacity, setBackgroundOpacity] = useState(1);
  const [showNavFunctions, setShowNavFuncitions] = useState(false);

  let viewMode = props?.view;
  let indexY = props?.indexY;

  useEffect(() => {
    const newOpacity = Math.max(0, 1 - indexY / 200);
    if (Number.isNaN(newOpacity)) {
      setBackgroundOpacity(1);
    } else {
      setBackgroundOpacity(newOpacity);
    }
    setShowNavFuncitions(false);
  }, [props?.indexY, backgroundOpacity]);

  const handleTypeBcg = (theme) => {
    if (theme === "dark") {
      return `linear-gradient(to bottom, rgba(0, 41, 116, ${backgroundOpacity}) 0%, rgba(19, 74, 149, ${backgroundOpacity}) 34%, rgba(17, 95, 167, ${backgroundOpacity}) 44%, rgba(13, 151, 216, ${backgroundOpacity}) 60%, rgba(12, 165, 229, ${backgroundOpacity}) 64%, rgba(39, 94, 146, ${backgroundOpacity}) 69%, rgba(0, 64, 104, ${backgroundOpacity}) 84%, rgba(2, 41, 74, ${backgroundOpacity}) 98%)`;
    } else {
      return `linear-gradient(to bottom, rgba(148, 37, 0, ${backgroundOpacity}) 0%, rgba(255, 166, 127, ${backgroundOpacity}) 36%, rgba(221, 166, 64, ${backgroundOpacity}) 44%, rgba(255, 236, 85, ${backgroundOpacity}) 60%, rgba(21, 225, 255, ${backgroundOpacity}) 64%, rgba(85, 255, 243, ${backgroundOpacity}) 69%, rgba(21, 193, 255, ${backgroundOpacity}) 98%)`;
    }
  };

  const toggleTheme = () => {
    // console.log();
    const newTheme = getTheme() === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  const navFunctionsMobile = {
    component: "container",
    style: {
      display: viewMode === "mobile" && showNavFunctions ? "flex" : "none",
      marginRight: "auto",
      flexDirection: "column",
      marginTop: Theme.sizes.size_200,
      marginLeft: Theme.sizes.size_8,
      width: Theme.sizes.size_182,
      height: 200,
      backgroundColor: "rgba(33, 30, 64, 0.8)",
      borderRadius: 4,
    },
    children: [
      TextButton({
        title: "Início",
        style: {
          backgroundColorHover: Theme.colors.secondary.regular,
          backgroundColorDefault: "transparent",
          titleColorHover: Theme.colors.primary.extra_dark,
          titleColorDefault: Theme.colors.primary.extra_ligth,
        },
        onClick: () => {
          console.log("Botão clicado");
        },
      }),
      TextButton({
        title: "Sobre",
        style: {
          backgroundColorHover: Theme.colors.secondary.regular,
          backgroundColorDefault: "transparent",
          titleColorHover: Theme.colors.primary.extra_dark,
          titleColorDefault: Theme.colors.primary.extra_ligth,
        },
        onClick: () => {
          console.log("Botão clicado");
        },
      }),
      TextButton({
        title: "Projetos",
        style: {
          backgroundColorHover: Theme.colors.secondary.regular,
          backgroundColorDefault: "transparent",
          titleColorHover: Theme.colors.primary.extra_dark,
          titleColorDefault: Theme.colors.primary.extra_ligth,
        },
        onClick: () => {
          console.log("Botão clicado");
        },
      }),
      TextButton({
        title: "Contato",
        style: {
          backgroundColorHover: Theme.colors.secondary.regular,
          backgroundColorDefault: "transparent",
          titleColorHover: Theme.colors.primary.extra_dark,
          titleColorDefault: Theme.colors.primary.extra_ligth,
        },
        onClick: () => {
          console.log("Botão clicado");
        },
      }),
      TextButton({
        title: "Acessar sistema",
        style: {
          backgroundColorHover: Theme.colors.secondary.regular,
          backgroundColorDefault: "transparent",
          titleColorHover: Theme.colors.primary.extra_dark,
          titleColorDefault: Theme.colors.primary.extra_ligth,
        },
        onClick: () => {
          console.log("Botão clicado");
        },
      }),
    ],
  };

  const navFunctions = {
    component: "container",
    style: {
      position: "absolute",
      zIndex: 9999,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 12,
    },
    children: [
      //logo octal + options
      {
        component: "container",
        style: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 14,
        },
        children: [
          Icon({
            src: logoDark,
            srcHover: logoLight,
            size: Theme.sizes.size_36,
            onPress: () => {
              setShowNavFuncitions(!showNavFunctions);
            },
          }),
          //criar map com options com click de navegação.
          {
            component: "container",
            style: {
              display:
                viewMode === "desktop" &&
                (backgroundOpacity > 0 || showNavFunctions)
                  ? "flex"
                  : "none",
              width: "auto",
              height: "auto",
              alignItems: "center",
              justifyContent: "center",
              gap: 14,
              transition: "opacity 0.2s ease",
              opacity: showNavFunctions === false ? backgroundOpacity : 1,
            },
            children: props?.item?.map((menu, index) => {
              return TextButton({
                title: menu?.option,
                style: {
                  titleColorHover: Theme.colors.primary.extra_ligth,
                  titleColorDefault: Theme.colors.primary.extra_dark,
                },
                onClick: () => menu?.onClick(),
              });
            }),
          },
        ],
      },
      //menuMobile
      {
        render: <Fade left>{Render(navFunctionsMobile)}</Fade>,
      },
      //toogleTheme + acesso ao sistema
      {
        component: "container",
        style: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 8,
          transition: "opacity 0.2s ease",
          opacity: showNavFunctions === false ? backgroundOpacity : 1,
        },
        children: [
          TextButton({
            title: "Acessar sistema",
            style: {
              titleBox: {
                display:
                  viewMode === "desktop" &&
                  (backgroundOpacity > 0 || showNavFunctions)
                    ? "flex"
                    : "none",
              },
              titleColorHover: Theme.colors.primary.extra_ligth,
              titleColorDefault: Theme.colors.primary.extra_dark,
            },
            onClick: () => {
              alert("navegarPara sistem");
            },
          }),
          Icon({
            src: getTheme() === "dark" ? iconSunDark : iconMoonDark,
            srcHover: getTheme() === "dark" ? iconSunLigth : iconMoonLight,
            size: Theme.sizes.size_24,
            style: {
              iconBox: {
                display:
                  backgroundOpacity > 0 || showNavFunctions ? "flex" : "none",
                width: "auto",
              },
            },
            //aqui é onde devo mudar meu thema no arquivo anterior.
            onPress: toggleTheme,
          }),
        ],
      },
    ],
  };

  return {
    component: "container",
    style: {
      position: "fixed",
      display: "flex",
      width: "100%",
      heigth: 92,
      minHeight: 92,
      maxHeight: 92,
      top: -2,
      zIndex: 1000,
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      // background: handleTypeBcg(getTheme()),
      transition: "background 0.5s ease-in-out",
    },
    children: [
      //moon
      {
        component: "container",
        style: {
          display: "none",
          width: "100%",
          justifyContent: "flex-end",
          paddingRight: 6,
          opacity: backgroundOpacity,
          backgroundImage: `url(${bcgStar_2})`,
          backgroundRepeat: "repeat",
          backgroundSize: "contain",
        },
        children: [
          {
            component: "image",
            src: menuMoon,
            style: {
              paddingTop: 14,
              width: 112,
              height: 64,
            },
          },
        ],
      },
      //bcg-sea-dark
      {
        component: "container",
        style: {
          display: "none",
          width: "100%",
          heigth: 31,
          minHeight: 31,
          // backgroundImage: `url(${bcgSeaDark})`,
          opacity: backgroundOpacity,
          backgroundRepeat: "repeat",
        },
      },
      //navFunctions
      {
        render: <Fade>{Render(navFunctions)}</Fade>,
      },
    ],
  };
};
