//default imports
import { Render } from "@8_dev/sjson-web";
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";

//Context
import { ShowPage } from "context/showPage";

//thema
import Theme from "theme";

//components
import PopUp from "components/web/popup";
import Intro from "../intro";

let Page = () => {
  // const { Data, Set } = Context();
  const Navigation = ShowPage();

  const [viewMode, setViewMode] = useState("desktop");

  useEffect(async () => {
    const { innerWidth: window_width, innerHeight: window_height } = window;
    const proportion = window_width / window_height;
    if (proportion < 1) setViewMode("mobile");
    else setViewMode("desktop");
    function handleResize() {
      const { innerWidth: window_width, innerHeight: window_height } = window;
      const proportion = window_width / window_height;
      if (proportion < 1) setViewMode("mobile");
      else setViewMode("desktop");
    }
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const contentParam = {
    viewMode,
  };

  return Render({
    component: "container",
    style: {
      display: "flex",
      width: "100vw",
      height: "100vh",
      overflowY: "hidden",
      overflowX: "hidden",
      // backgroundColor: "blue",
      // backgroundImage: `linear-gradient(to top, ${Theme.colors.bg.dark.bottom} 10%, ${Theme.colors.bg.dark.top} 34%)`,
    },
    children: [PopUp(), Intro(contentParam)],
  });
};

export default Page;
