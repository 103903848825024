// criar state para receber uma string como parametro identificadno o thema. A partir disso, o que vier posso trabalhar meu thema de maneira dinamica.
import { useState } from "react";

let theme = "light";

export const getTheme = () => theme;

export const setTheme = (newTheme) => {
  theme = newTheme;
};

const colors = {
  primary: {
    regular: "#211E40",
    medium: "#605E7D",
    light: "#A3A1B7",
    extra_ligth: "#E7E7EE",
    dark: "#0D0B25",
    extra_dark: "#060414",
  },
  secondary: {
    regular: "#00FFC3",
    medium: "#99FFF9",
    light: "#BFFFFE",
    extra_light: "#E6FFFF",
    dark: "#008174",
    extra_dark: "#00423F",
  },
  neutral: {
    regular: "#848484",
    medium: "#A8A8A8",
    light: "#D2D2D2",
    extra_ligth: "#FFFFFF",
    dark: "#3A3A3A",
    extra_dark: "#000000",
  },
  sucess: {
    regular: "#00A468",
    medium: "#73CDAC",
    light: "#99DBC3",
    extra_ligth: "#E6F6F0",
    dark: "#00422A",
    extra_dark: "#00100A",
  },
  warning: {
    regular: "#FFD300",
    medium: "#FFE773",
    light: "#FFED99",
    extra_ligth: "#FFFBE6",
    dark: "#665400",
    extra_dark: "#1A1500",
  },
  error: {
    regular: "#C40234",
    medium: "#D64E71",
    light: "#E79AAE",
    extra_ligth: "#F9E6EB",
    dark: "#4E0115",
    extra_dark: "#140005",
  },
  info: {
    regular: "#0188BF",
    medium: "#73BEDC",
    light: "#99CFE5",
    extra_ligth: "#E6F3F9",
    dark: "#00364C",
    extra_dark: "#000E13",
  },
  bg: {
    light: {
      top: "#19C5FF",
      bottom: "#000000",
    },
    dark: {
      top: "#02294A",
      bottom: "#000000",
    },
  },
  nav: {
    moon: "#86FFFF",
    sun: "#FF9700",
  },
};
const elevations = {
  1: "0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)",
  2: "0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)",
  3: "0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16)",
  4: "0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)",
  5: "0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16)",
  6: "0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24)",
};
const fontFamilies = {
  primary: "Roboto",
  secondary: "Merriweather",
  regular: "Poppins",
};
const fontWeight = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};
const sizes = {
  size_8: 8,
  size_10: 10,
  size_12: 12,
  size_14: 14,
  size_16: 16,
  size_18: 18,
  size_20: 20,
  size_24: 24,
  size_26: 26,
  size_28: 28,
  size_32: 32,
  size_36: 36,
  size_44: 44,
  size_48: 48,
  size_50: 50,
  size_60: 60,
  size_80: 80,
  size_96: 96,
  size_182: 182,
  size_192: 192,
  size_200: 200,
  size_256: 256,
};
const text = {
  primary: {
    title: {
      extra_large: (theme) => ({
        fontFamily: fontFamilies.primary,
        color: theme === "dark" ? colors.primary.regular : "red",
        fontWeight: fontWeight.medium,
        // fontSize: sizes.size_60,
      }),
      large: (theme) => ({
        fontFamily: fontFamilies.primary,
        color:
          theme === "dark" ? colors.secondary.regular : colors.primary.medium,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_48,
      }),
      big: (theme) => ({
        fontFamily: fontFamilies.primary,
        color:
          theme === "dark" ? colors.secondary.regular : colors.primary.medium,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_36,
      }),
      medium: (theme) => ({
        fontFamily: fontFamilies.primary,
        color:
          theme === "dark" ? colors.secondary.regular : colors.primary.medium,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_32,
      }),
      small: (theme) => ({
        fontFamily: fontFamilies.primary,
        color:
          theme === "dark" ? colors.secondary.regular : colors.primary.medium,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_24,
      }),
    },
    subtitle: {
      medium: (theme) => ({
        fontFamily: fontFamilies.primary,
        color:
          theme === "dark" ? colors.neutral.extra_ligth : colors.primary.dark,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_32,
      }),
      regular: (theme) => ({
        fontFamily: fontFamilies.primary,
        color:
          theme === "dark" ? colors.secondary.regular : colors.primary.medium,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_24,
      }),
      demi: (theme) => ({
        fontFamily: fontFamilies.primary,
        color:
          theme === "dark" ? colors.secondary.regular : colors.primary.medium,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_20,
      }),
      small: (theme) => ({
        fontFamily: fontFamilies.primary,
        color:
          theme === "dark" ? colors.secondary.regular : colors.primary.medium,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_18,
      }),
    },
    button: {
      medium: (theme) => ({
        fontFamily: fontFamilies.primary,
        color:
          theme === "dark" ? colors.neutral.extra_ligth : colors.primary.dark,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_32,
      }),
      regular: (theme) => ({
        fontFamily: fontFamilies.primary,
        color:
          theme === "dark" ? colors.secondary.regular : colors.primary.medium,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_26,
      }),
      small: (theme) => ({
        fontFamily: fontFamilies.primary,
        color:
          theme === "dark" ? colors.secondary.regular : colors.primary.medium,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_20,
      }),
    },
    text: {
      big: (theme) => ({
        fontFamily: fontFamilies.regular,
        color: colors.primary.dark,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_20,
      }),
      regular: (theme) => ({
        fontFamily: fontFamilies.regular,
        color: colors.primary.dark,
        fontWeight: fontWeight.regular,
        fontSize: sizes.size_18,
      }),
      extra_small: (theme) => ({
        fontFamily: fontFamilies.regular,
        color:
          theme === "dark" ? colors.secondary.regular : colors.primary.medium,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_16,
      }),
    },
    nav: {
      nav: (theme) => ({
        fontFamily: fontFamilies.primary,
        color:
          theme === "dark" ? colors.neutral.extra_ligth : colors.primary.dark,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_24,
      }),
      hover: (theme) => ({
        fontFamily: fontFamilies.primary,
        color:
          theme === "dark" ? colors.secondary.regular : colors.primary.medium,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_26,
      }),
      small: (theme) => ({
        fontFamily: fontFamilies.primary,
        color:
          theme === "dark" ? colors.secondary.regular : colors.primary.medium,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_16,
      }),
    },
    form: {
      big: (theme) => ({
        fontFamily: fontFamilies.primary,
        color:
          theme === "dark" ? colors.neutral.extra_ligth : colors.primary.dark,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_32,
      }),
      regular: (theme) => ({
        fontFamily: fontFamilies.primary,
        color:
          theme === "dark" ? colors.secondary.regular : colors.primary.medium,
        fontWeight: fontWeight.medium,
        fontSize: sizes.size_24,
      }),
    },
  },
};
const padding = {
  small: 5,
  normal: 10,
  medium: 15,
  big: 35,
};
const margin = {
  small: 5,
  normal: 10,
  medium: 15,
  big: 35,
};
const opacity = {
  0: "09",
  10: "0.1",
  20: "0.2",
  30: "0.3",
  40: "0.4",
  50: "0.5",
  60: "0.6",
  70: "0.7",
  80: "0.8",
  90: "0.9",
  100: "1",
  101: "2",
};

export default {
  colors,
  elevations,
  fontFamilies,
  fontWeight,
  sizes,
  text,
  padding,
  margin,
  opacity,
};
