//import default
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { Context } from "context/data";

//Tema
import Theme from "theme";

//images
import HortaIcon from "assets/images/menu/icon-horta.svg";

const PageID = "Horta";

export default ({ viewMode, ContentShow }) => {
  const { PageInfo, GoPage, GoInitPage } = ShowPage();
  const { Data, Set } = Context();
  return {
    render: (
      <Fade left>
        {Render({
          component: "container",
          style: {
            display: ContentShow === PageID ? "flex" : "none",
            flexDirection: viewMode === "desktop" ? "row" : "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            padding: 12,
            gap: 12,
          },
          children: [
            //image
            {
              component: "container",
              style: {
                display: viewMode === "desktop" ? "flex" : "none",
                width: "16vw",
                height: "16vw",
                aspectRatio: "1/1",
                borderRadius: "50%",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#FFE5C2",
              },
              children: [
                {
                  component: "image",
                  src: HortaIcon,
                  style: {
                    width: "92%",
                    height: "92%",
                    alignItems: "center",
                    aspectRatio: "1/1",
                  },
                },
              ],
            },

            //textComponents
            {
              component: "container",
              style: {
                display: "flex",
                height: "auto",
                flexDirection: "column",
                alignItems: viewMode === "desktop" ? "flex-start" : "center",
                justifyContent: "flex-end",
                marginLeft: viewMode === "desktop" ? 32 : 0,
                gap: 22,
              },
              children: [
                //anoDev
                {
                  component: "text",
                  text: "2019 - 2021",
                  style: {
                    color: "#B5B5B5",
                    fontFamily: "Poppins",
                  },
                },
                //NameProject
                {
                  component: "text",
                  text: "Petin Digital",
                  style: {
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 32,
                    fontFamily: "Poppins",
                  },
                },
                //Description
                {
                  component: "text",
                  text: "Em um ano e meio, com a primeira versão da biblioteca desenvolvemos do zero o projeto de hortifrut no CEAGESP (da horda).",
                  style: {
                    maxWidth: "60%",
                    color: "#B5B5B5",
                    fontFamily: "Poppins",
                    textAlign: "justify",
                  },
                },
                //Equipe
                {
                  component: "text",
                  text: "Equipe",
                  style: {
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 18,
                    fontFamily: "Poppins",
                  },
                },
                //textDestaque
                {
                  component: "container",
                  style: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 12,
                  },
                  children: [
                    {
                      component: "text",
                      text: "4 WEB",
                      style: {
                        padding: 8,
                        borderRadius: 24,
                        color: "black",
                        backgroundColor: "#00FFC3",
                        fontFamily: "Poppins",
                      },
                    },
                    {
                      component: "text",
                      text: "2 APP",
                      style: {
                        padding: 8,
                        borderRadius: 24,
                        color: "black",
                        backgroundColor: "#00FFC3",
                        fontFamily: "Poppins",
                      },
                    },
                    {
                      component: "text",
                      text: "2 BI",
                      style: {
                        padding: 8,
                        borderRadius: 24,
                        color: "black",
                        backgroundColor: "#00FFC3",
                        fontFamily: "Poppins",
                      },
                    },
                  ],
                },
              ],
            },
          ],
        })}
      </Fade>
    ),
  };
};
